// https://stackoverflow.com/a/12781905
function checkIfSmallDevice() {
    return (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) < 768;

}

// https://pawelgrzybek.com/the-intersection-observer-api-explained/
// adopted.
// --> plays first video in viewport. ON MOBILE ONLY (<768px):
function onEntry(entry) {
    if (!checkIfSmallDevice()) { return; }

    let onePlayed = false;
    entry.forEach((change) => {
        if((change.isVisible || change.isIntersecting) && !onePlayed) {
            change.target.play();
            onePlayed = true;
        }
        else {
            change.target.pause();
        }
    });
}
const options = {
    threshold: [0.5]
};

window.addEventListener("turbo:load", () => {
// instantiate a new Intersection Observer
const observer = new IntersectionObserver(onEntry, options);

// loop through all elements
const videos = document.querySelectorAll('video.product-video');
// pass each element to observe method
// ES2015 for-of loop can traverse through DOM Elements
for (let elm of videos) {
    observer.observe(elm);
}

// https://gist.github.com/adamloving/ee4d980e3de2fc125207#gistcomment-239565
// --> plays video on mouse hover. ONLY IF NOT MOBILE (>= 768px):
    [].forEach.call(videos, function (item) {
        item.addEventListener('mouseover', hoverVideo, false);
        item.addEventListener('mouseout', hideVideo, false);
    });

    function hoverVideo(e) {
        if (checkIfSmallDevice()) {
            return;
        }
        this.play();
    }

    function hideVideo(e) {
        if (checkIfSmallDevice()) {
            return;
        }
        this.pause();
    }
});

if(window.setQuickVariant == null) {
    window.setQuickVariant = function(event, domId) {
        const val = event.target.value;
        const btnCart = document.querySelector('#' + domId + ' a.btn-cart');
        const link = new URL(btnCart.getAttribute('href'), window.location.origin);
        link.searchParams.set('variant', val);
        btnCart.setAttribute('href', link.href);
    }
}

if(window.setQuickQuantity == null) {
    window.setQuickQuantity = function(event, domId) {
        const val = event.currentTarget.value;
        const btnCart = document.querySelector('#' + domId + ' a.btn-cart');
        const link = new URL(btnCart.getAttribute('href'), window.location.origin);
        link.searchParams.set('quantity', val);
        btnCart.setAttribute('href', link.href);
    }
}

if(window.changeQuickQuantity == null) {
    window.changeQuickQuantity = function(event, domId) {
        const add = parseInt(event.currentTarget.dataset.add);
        const quantityInput = document.querySelector('#' + domId + ' .input-quantity');
        const newQuantity = parseInt(quantityInput.value) + add;
        if (newQuantity >= 0) {
            quantityInput.value = parseInt(quantityInput.value) + add;
            quantityInput.onchange({ currentTarget: { value: quantityInput.value }}, domId);
        }
    }
}
